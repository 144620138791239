<template>
	<div :style="{ height: 0 }" class="borders">
		<div v-if="this.$store.getters.isTerminal">
			<h2 class="pb-4 mt-3 pl-8">{{ $t('kpi.chartSubtitle') }}</h2>
			<v-row justify="start" class="pb-4 mt-3 pl-6">
				<v-col style="margin-left=30px">
					<v-menu
						:close-on-content-click="false"
						v-model="modelMenu"
						:nudge-right="40"
						transition="scale-transition"
						offset-y
						min-width="290px"
						><template v-slot:activator="{ on, attrs }">
							<pui-text-field
								v-on="on"
								v-bind="attrs"
								append-icon="far fa-calendar-day"
								solo
								outlined
								flat
								required
								class="dateRangeField"
								@click:append="modelMenu = true"
								v-model="getDatePlaceholder"
								:label="$t('kpi.dateRange')"
								toplabel
							></pui-text-field>
						</template>
						<v-date-picker time range v-model="date.range" @click:append="modelMenu = true"></v-date-picker>
					</v-menu>
				</v-col>
				<v-col>
					<pui-select
						:label="$t('kpi.rangeGap')"
						itemText="text"
						itemValue="value"
						toplabel
						:items="viewRange"
						v-model="avgDays"
						clearable
						reactive
					>
					</pui-select>
				</v-col>
				<v-col>
					<pui-text-field toplabel :label="$t('kpi.imoFilter')" v-model="extra.imo"></pui-text-field>
				</v-col>
				<v-spacer></v-spacer>
				<v-col>
					<div class="search">
						<v-btn color="primary" @click.prevent="find('generalChartUpdates')">{{ $t('kpi.find') }}</v-btn>
					</div>
				</v-col>
			</v-row>
		</div>
		<div class="mx-5">
			<v-row class="d-flex pa-3">
				<v-col cols="6" v-for="chart in filteredCharts" :key="chart.id">
					<operations-chart
						subscribe_to="generalChartUpdates"
						:resized="{ height: '400' }"
						:range="range"
						:configuration="chart"
						:id="chart.id"
						:extra="extra"
					>
					</operations-chart>
				</v-col>
			</v-row>
		</div>
		<div>
			<h2 v-if="dashboard" class="pb-4 mt-3 pl-8">{{ $t('kpi.dashboardSubtitle') }}</h2>
			<v-row class="d-flex pa-3">
				<v-col cols="12">
					<pui-dashboard v-if="dashboard" :defaultDashboard="dashboard" />
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			dashboard: null,
			not_access: '',
			charts: [],
			extra: {},
			avgDays: null,
			internalDateModel: null,
			imoFilter: false,
			modelMenu: false,
			date: {
				range: []
			},
			max_date_period: {
				months: {
					regular: 3,
					filtered: 6
				}
			},
			not_access: '',
			viewRange: [
				{
					text: this.$t('kpi.week'),
					value: 7
				},
				{
					text: this.$t('kpi.month'),
					value: 30
				}
			]
		};
	},
	methods: {
		find(group) {
			this.$puiEvents.$emit(group);
		}
	},
	computed: {
		getDatePlaceholder() {
			if (!this.date.range[0] || !this.date.range[1]) {
				return this.$t('kpi.lastMonth');
			}
			return (
				'From ' + new Date(this.date.range[0]).toISOString().split('T')[0] + ' to ' + new Date(this.date.range[1]).toISOString().split('T')[0]
			);
		},
		range() {
			if (this.date.range[0] && this.date.range[1]) {
				return {
					from: new Date(this.date.range[0]).toISOString(),
					to: new Date(this.date.range[1]).toISOString()
				};
			}
		},
		filteredCharts() {
			return this.charts.filter((c) => {
				return this.imoFilter ? c.group == 'imoFiltered' : c.group != 'imoFiltered';
			});
		}
	},
	created() {
		this.$store.dispatch('getAllConfigPortCompany'); //it lost that info when screen was refreshed
	},
	mounted() {
		//George's code for Echarts
		this.date.range[1] = new Date().toISOString();
		const monthAgo = new Date(this.date.range[1]);
		monthAgo.setMonth(monthAgo.getMonth() - 1);
		this.date.range[0] = monthAgo.toISOString();
		this.$puiRequests.getRequest('/chart/permissions', {}, (r) => {
			this.charts = r.data;
		});

		//Miguel's code for MX (table pui_dashboards) applied to all ports
		const portId = window.localStorage.getItem('workingPortsId');

		let dashboardId = this.$store.getters.getDashboardIdBy(portId);
		if (dashboardId) {
			this.$puiRequests.getRequest('/puidashboard/get', { id: dashboardId }, (response) => {
				if (response && response.data) {
					this.dashboard = {
						id: response.data.id,
						name: response.data.name,
						definition: JSON.parse(response.data.definition)
					};
				}
			});
		} else {
			this.not_access = this.$t('errors.nodashboardconfigured');
		}
	},
	watch: {
		avgDays(n, o) {
			if (Boolean(n)) {
				this.extra.avgDays = n.value;
			} else {
				this.extra.avgDays = n;
			}
		},
		imoFilter(n, o) {
			if (!n) {
				this.extra.imo = null;
			}
		}
	}
};
</script>
<style scoped>
.borders > div {
	padding-top: 15px;
}
.vue-grid-layout {
	background: white !important;
}
.search {
	padding-top: 20px;
	margin-right: 37px;
	text-align: right;
}
</style>
